<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <!-- prepend-icon="mdi-clock-time-four-outline" -->
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="TimeValue"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        hide-details="auto"
        :outlined="outlined"
        :dense="dense"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu2"
      format="24hr"
      v-model="time"
      full-width
      @click:minute="$refs.menu.save(time)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  model: { prop: "value", event: "input" },

  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String],
      default: "",
    },
    dense: {
      type: [Boolean],
      default: false,
    },
    outlined: {
      type: [Boolean],
      default: false,
    },
  },
  mounted() {
    console.log(this.label);
    if (this.value != null && this.value != "") this.time = this.value;
  },
  computed: {
    TimeValue() {
      this.$emit("input", this.time);
      return this.time;
    },
  },

  methods: {},
  data: () => ({
    time: null,
    menu2: false,
    modal2: false,
  }),
};
</script>

<style></style>
