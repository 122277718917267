<template>
  <!-- start form -->
  <v-card class="ma-0 pa-0">
    <v-card-text class="ma-0 pa-0">
      <v-alert
        v-if="success_text != ''"
        dense
        text
        class="text-center mt-0"
        type="success"
      >
        <strong>สำเร็จ </strong> {{ success_text }}
      </v-alert>
      <v-alert
        v-if="error_text != ''"
        dense
        text
        class="text-center mt-0"
        type="error"
      >
        <strong>ผิดพลาด </strong> {{ error_text }}
      </v-alert>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title class="info--text mt-0 pt-0 pb-2 pl-0"
          >ข้อมูลติดต่อ</v-card-title
        >
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-select
              v-model="form.ReBtHostID"
              :items="id_list"
              color="pink"
              label="รหัส"
              ref="cbReBtHostID"
              required
              outlined
              hide-details="auto"
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.HostRefID"
              color="pink"
              label="รหัสส่ง"
              ref="txtHostRefID"
              required
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.Name"
              color="pink"
              label="ชื่อ"
              ref="txtName"
              required
              dense
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.Tel"
              color="pink"
              label="เบอร์โทร"
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.Line"
              color="pink"
              label="ไลน์"
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.Address"
              color="pink"
              label="ที่อยู่"
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card-title class="info--text mt-3 pt-0 pb-0 pl-0"
          >ตั้งค่า</v-card-title
        >

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.called_me"
              color="pink"
              label="ชื่อส่งเจ้า"
              required
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <CloseTimePicker
              label="เวลาปิด"
              v-model="form.CloseTime"
              outlined
              dense
            ></CloseTimePicker>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.Credit"
              type="number"
              color="pink"
              label="เครดิต"
              required
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-switch
              class="mt-0"
              color="primary"
              v-model="form.Status"
              :label="getStatusText()"
            ></v-switch>
          </v-col>
        </v-row>
        <v-card-title class="info--text pl-0 pt-0 pb-0">บันทึก</v-card-title>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              v-model="form.Note"
              label="โน๊ต"
              auto-grow
              color="pink"
              rows="3"
              row-height="25"
              outlined
              hide-details="auto"
              dense
            ></v-textarea>
            <!-- :class="{'dense': $vuetify.breakpoint.smAndDown}" -->
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancle">
        ยกเลิก
      </v-btn>
      <v-btn color="blue darken-1" text @click="submitData">
        บันทึก
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import VueTimepicker from 'vue2-timepicker'
// import 'vue2-timepicker/dist/VueTimepicker.css'

import CloseTimePicker from "@/components/Controls/cTimePicker";

export default {
  name: "Add_Detail",
  components: {
    // VueTimepicker,
    CloseTimePicker,
  },
  methods: {
    getStatusText() {
      if (this.form.Status == "0") return "สถานะ : ระงับ";
      return "สถานะ : ใช้งาน";
    },
    OnShow() {
      //   alert("Show");
      this.form.ReBtHostID = this.id_list[0];
      this.form.Name = "";
      this.form.Tel = "";
      this.form.Line = "";
      this.form.Priority = 0;
      this.form.Address = "";
      this.form.HostRefID = "";
      this.form.CloseTime = "00:00";
      this.form.Credit = 0;
      this.form.called_me = "";
      this.form.Status = 1;
      this.form.Note = "";
      this.error_text = "";
      this.success_text = "";
      this.$refs.txtName.focus();
    },
    OnUpdateSuccess(p_RebtHostID, p_RebtHostName) {
      this.OnShow();
      this.success_text =
        "เพิ่มเจ้ามือ " + p_RebtHostID + " " + p_RebtHostName + " ลงในระบบ";
    },
    submitData() {
      this.success_text = "";
      if (this.form.ReBtHostID == "") {
        this.error_text = "ต้องกรอก รหัสเจ้ามือส่งออก";
        this.$refs.cbReBtHostID.focus();
      } else if (this.form.Name == "") {
        this.error_text = "ต้องกรอก ชื่อเจ้ามือส่งออก";
        this.$refs.txtName.focus();
      } else {
        if (this.submitted == false) this.OnRegister(this.form);
      }
    },
    async OnRegister(credentials) {
      this.submitted = true;
      //แปลง status ให้ตรงกับฐานข้อมูล 0 คือใช้งาน 1 คือระงับ
      credentials.Status = !credentials.Status;
      credentials.Method = "POST";
      //แปลงอักขระพิเศษให้เป็น HTML CODE
      //   credentials.Name = encodeURIComponent(credentials.Name);
      this.$store.dispatch("bookie/SendBookieData", credentials).then(
        (response) => {
          this.submitted = false;
          console.log(credentials);
          this.$emit("SetReBtHostID", {
            ReBtHostID: credentials.ReBtHostID,
            ReBtHostName: credentials.Name,
          });
          //   console.log(response);
          //   this.$alert(
          //     "สำเร็จ.",
          //     "ลงทะเบียนลูกค้ารหัส " + this.form.Name + "เสร็จสิ้น",
          //     "success"
          //   ).then(
          //     () => this.$refs.form.reset(),
          //     this.$refs.form.resetValidation(),
          this.$emit("Next");
          //     //emit
          //     // this.$emit("cus_pick", response)
          //   );
        },
        (error) => {
          //   this.login_message(error);
          console.log(error);
          switch (error) {
            case "ID_DUPLICATE":
              this.error_text = "รหัสเจ้ามือ ซ้ำกับที่มีในระบบ";
              //   this.$alert(
              //     "รหัสลูกค้าซ้ำกับที่มีในระบบ.",
              //     "ผิดพลาด",
              //     "error"
              //   ).then(() => this.$refs.txtName.focus());
              break;
            case "NAME_ALREADY_EXISTS":
              this.error_text = "ชื่อเจ้ามือ ซ้ำกับที่มีในระบบ";
              this.$refs.txtName.focus();
              break;
            // case "Error01":
            //   this.$router.push("/Login");
            //   break;
            // case "Error02":
            //   this.$router.push("/Login");
            //   break;
          }
          this.submitted = false;
        }
      );
    },
    cancle() {
      this.$emit("cancel");
    },
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    //stepper
    e1: 1,
    // form
    valid: true,

    form: {
      ReBtHostID: "",
      Name: "",
      Tel: "",
      Line: "",
      Priority: 0,
      Address: "",
      HostRefID: "",
      CloseTime: "00:00",
      Credit: 0,
      called_me: "",
      Status: 1,
      Note: "",
    },
    submitted: false,
    error_text: "",
    success_text: "",
    // rules: {
    //   id: [(val) => (val || "").length > 0 || "This field is required"],
    //   name: [
    //     (v) => !!v || "ต้องกรอกชื่อ",
    //     (v) => (v && v.length <= 50) || "ชื่อใส่ได้ไม่เกิน 50 ตัวอักษร",
    //   ],
    //   pw: [
    //     (v) => !!v || "ต้องใส่รหัสผ่าน",
    //     (v) => (v && v.length <= 12) || "รหัสผ่านใส่ไมไ่ด้เกิน 25 ตัวอักษร",
    //   ],
    //   tel: [
    //     (v) => (v && v.length <= 25) || "เบอร์โทรใส่ไมไ่ด้เกิน 25 ตัวอักษร",
    //   ],
    //   line_account: [
    //     (v) => (v && v.length <= 100) || "ไลน์ใส่ไมไ่ด้เกิน 100 ตัวอักษร",
    //   ],
    // },

    //select
    //id_list: ["01", "02", "03", "04", "05"],
  }),

  computed: {
    id_list() {
      let id = [];
      for (let i = 1; i <= 99; i++) {
        id.push(("0" + i).slice(-2));
      }
      for (let key in this.$store.getters["bookie/Bookies"]) {
        let index = id.indexOf(
          this.$store.getters["bookie/Bookies"][key]["id"]
        );
        index = ("0" + index).slice(-2);
        id.splice(index, 1);
      }
      return id;
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .v-text-field input {
//   font-size: 0.85em !important;
// }

// ::v-deep .v-text-field label {
//   font-size: 0.78em !important;
// }

// ::v-deep .v-text-field .v-counter {
//   font-size: 0.8em !important;
// }

// ::v-deep .v-text-field .v-messages__message {
//   font-size: 0.7em !important;
// }
// ::v-deep .v-text-field .v-select__selection {
//   font-size: 0.85em !important;
// }

/* ::v-deep .v-text-fields {
    font-size: 8px !important;
} */
</style>
