<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="bookie_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="75vh"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn rounded id="menu-activator" color="info" dark>
            <v-icon left>
              mdi-chevron-down
            </v-icon>
            จัดการ
          </v-btn>

          <v-menu activator="#menu-activator" offset-y v-model="showMenu">
            <v-list>
              <AddData @Close="DialogClose" />
              <v-list-item
                @click="printSection"
                style="background-color: lightgreen;"
              >
                <v-list-item-title style="color: black;">
                  <v-icon left>
                    mdi-printer
                  </v-icon>
                  พิมพ์</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            class="ml-1"
            rounded
            outlined
            id="menu-activator"
            color="info"
            dark
          >
            <v-icon style="color: lightblue ;">
              mdi-bookmark
            </v-icon>
          </v-btn>

          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn rounded outlined color="pink">
            <v-icon left>
              mdi-filter-variant
            </v-icon>
            ตัวกรอง
          </v-btn>
        </v-toolbar>
        <v-alert dense text class="text-center" type="success">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ bookie_count }}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <!-- @contextmenu="popup_menu($event, item)" -->
        <tr
          @touchstart="touch = true"
          @click="row_click($event, item)"
          @contextmenu="popup_menu($event, item)"
          :class="{ 'green lighten-4': item.id === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td :class="id_column">{{ item.id }}</td>
          <td align="start" :class="getNamesColor(item.Status)">
            {{ item.Name }}
          </td>
          <td>{{ item.Line }}</td>
          <td>{{ item.Tel }}</td>
          <td>{{ item.Rate3Top }} / {{ item.Rate2Down }}</td>
          <td>{{ item.Percent3Top }} / {{ item.Percent2Down }}</td>
          <td>{{ item.PriceMax3Top }} / {{ item.PriceMax2Down }}</td>
          <td>{{ item.called_me }}</td>
          <td>{{ item.HostRefID }}</td>
          <td align="start" :class="getStatusColor(item.Status)">
            {{ getStatusText(item.Status) }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" @del="Del" />
    <Del ref="DeleteDlg" />
  </div>
</template>

<script>
import PopupMenu from "@/components/Data/Export/Bookie/Genaral/Popup_Menu";
import AddData from "@/components/Data/Export/Bookie/Genaral/Add_Data";
import Del from "@/components/Data/Export/Bookie/Genaral/Del";

export default {
  name: "Bookie_Datatable",

  components: {
    PopupMenu,
    AddData,
    Del,
  },
  created() {
    //โหลด ตาราง
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    bookie_data() {
      return this.$store.getters["bookie/Bookies"];
    },
    bookie_count() {
      return this.$store.getters["bookie/Bookies"].length;
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        Method: "GETLIST",
        ListType: "GENARAL_LIST",
      };
      await this.$store.dispatch("bookie/SendBookieData", credentials).then(
        (response) => {
          //console.log(response);
          this.UpdateCodeTable = parseInt(response.updatecode);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getStatusText(status) {
      const statusText = ["ใช้งาน", "ระงับ"];
      return statusText[parseInt(status)];
    },
    getStatusColor(status) {
      const statusColor = ["green--text", "red--text"];
      return statusColor[parseInt(status)] + " pl-10";
    },
    getNamesColor(status) {
      const statusColor = ["", "red--text"];
      return statusColor[parseInt(status)];
    },
    row_click: function(e, item) {
      if (this.selectedId == item.id) {
        if (this.touch == true) this.popup_menu(e, item);
      }

      this.selectedId = item.id;
      this.$set(item, "selected", true);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(item.id, item.Status);
        this.selectedId = item.id;
        //console.log(item);
      });
    },
    printSection() {
      this.$htmlToPaper("printSection");
    },
    DialogClose() {
      this.showMenu = false;
    },
    Del(p_id) {
      var HostName = ";";
      this.$store.getters["bookie/Bookies"].forEach((element) => {
        if (element.id == p_id) {
          HostName = element.Name;
        }
      });
      this.$refs.DeleteDlg.Show(p_id, HostName, this.UpdateCodeTable);
    },
  },
  data: () => ({
    selectedId: -1,
    touch: false,
    showMenu: false,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,
    UpdateCodeTable: 0,

    headers: [
      {
        text: "#",
        value: "id",
        width: "1%",
      },

      {
        text: "ชื่อ",
        value: "Name",
        align: "start",
        width: "10%",
        sortable: false,
      },
      {
        text: "ไลน์",
        value: "Line",
        align: "start",
        width: "10%",
      },
      { text: "เบอร์โทร", value: "Tel", align: "start", width: "10%" },
      { text: "อัตราจ่าย", value: "Rate", align: "start", width: "10%" },
      { text: "เปอร์เซ็นต์", value: "Percent", align: "start", width: "10%" },
      { text: "ลิมิต", value: "Limit", align: "start", width: "10%" },
      { text: "ชื่อส่งเจ้า", value: "CalledMe", align: "start", width: "10%" },
      { text: "รหัสส่ง", value: "ReBtHostID", align: "start", width: "10%" },
      { text: "สถานะ", value: "Status", align: "start", width: "29%" },
    ],
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: #026921 !important;
  color: white;
  /* text-align: center !important;  
    padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: #026921 !important;
  color: white !important;
  padding-top: 15px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  border-right: 1px solid;
  font-size: 18px !important;
  border-top: 1px solid;
  border-color: lightgray;
}

/* ::v-deep table {
  background-color: #fafafa;
} */

tbody tr:nth-of-type(even) {
  background-color: rgb(240, 245, 245);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(255, 255, 255);
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(88, 88, 88);
  font-weight: normal;
  border-right: 1px solid;
  border-color: lightgray;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 0.95em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}
</style>
