<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="red darken-4" dense>
        <v-toolbar-title>ลบเจ้ามือ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title class="justify-center h1">
        ยืนยันลบเจ้ามือ รหัส {{ ReBtHostID }} ชื่อ {{ ReBtHostName }}
      </v-card-title>
      <v-divider></v-divider>
      <v-alert
        border="bottom"
        class="pa-2 ma-3 red--text text--darken-4"
        color="red darken-4"
        type="error"
        colored-border
        dense
      >
        คำเตือน ถ้าลบเจ้ามือสิ่งเหล่านี้จะเกิดขึ้น ไม่สามารถกู้คืนกลับมาได้
      </v-alert>
      <v-card-text class="pb-0">
        <!-- colored-border -->
        <v-alert type="warning" color="orange darken-4" dark border="left" dense
          >ข้อมูลทั้งหมด</v-alert
        >
        <v-alert
          class="mt-0"
          type="warning"
          color="orange darken-4"
          border="left"
          dense
          >เลขอั้น</v-alert
        >
        <v-alert
          class="mt-0"
          type="warning"
          color="orange darken-4"
          border="left"
          dense
          >บัญชีรายรับรายจ่าย</v-alert
        >
        <v-alert
          class="mt-0"
          type="warning"
          color="orange darken-4"
          border="left"
          dense
          >ประวัติการส่งออก</v-alert
        >
        <v-alert
          class="mt-0"
          type="warning"
          color="orange darken-4"
          border="left"
          dense
          ><div>โพยที่ส่งงวดนี้ รวมถึงยอดรวมจะถูกดึงออกมาด้วย</div>
          <div class="mt-1">
            จำนวนโพย <strong>0</strong> ใบ ยอดรวม <strong>0</strong> บาท
          </div></v-alert
        >
        <v-alert
          class="mt-0"
          type="warning"
          color="orange darken-4"
          border="left"
          dense
          >หากไม่แน่ใจแนะนำให้ใช้เมนู
          <strong class="green--text text--accent-1">หยุดส่ง</strong>
          แทนการลบออก</v-alert
        >
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <strong>ใส่ pin เพื่อยืนยันการลบ</strong>

        <CodeInput
          :loading="false"
          :autoFocus="true"
          :fields="4"
          class="input ml-3"
          v-on:change="onChange"
          v-on:complete="onComplete"
          ref="pincode"
          dense
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
  name: "Del",

  components: { CodeInput },
  // watch: {
  //   dialog(val) {
  //     if (val) {
  //       setTimeout(() => {
  //         this.e1 = 1;
  //         this.$refs.cAddDetail.OnShow();
  //       });
  //     } else {
  //       this.$emit("Close");
  //     }
  //   },
  // },
  //   watch: {
  //     dialog(visible) {
  //       if (visible) {
  //         // Here you would put something to happen when dialog opens up
  //         // this.$refs.pincode.values = ["", "", "", "", ""];
  //         console.log("Dialog was opened!")
  //         this.$refs.pincode.values = ["", "", "", "", ""];
  //       } else {
  //         console.log("Dialog was closed!");
  //       }
  //     },
  //   },
  methods: {
    Show(p_ReBtHostID, p_ReBtHostName, p_UpdateCodeTable) {
      this.ReBtHostID = p_ReBtHostID;
      this.ReBtHostName = p_ReBtHostName;
      this.UpdateCodeTable = p_UpdateCodeTable;
      this.dialog = true;
      setTimeout(() => {
        this.$refs.pincode.values = ["", "", "", ""];
      });
    },
    OnCancel() {
      this.dialog = false;
    },
    onChange(v) {
      console.log("onChange ", v);
      console.log(this.$refs.pincode.values);
    },
    onComplete(v) {
      //pinn
      console.log("onComplete ", v);
      this.doDel(v);
    },
    async doDel(p_PinCode) {
      let credentials = {
        Method: "DEL",
        BookieID: this.ReBtHostID,
        PinCode: p_PinCode,
        CodeNo: this.UpdateCodeTable,
      };
      await this.$store.dispatch("bookie/SendBookieData", credentials).then(
        (response) => {
          //console.log(response);
          //emit to main
          //   this.$alert("Header","Text","success");
          this.$fire({
            title: "ลบสำเร็จ",
            text: "รหัส " + this.ReBtHostID + " ชื่อ " + this.ReBtHostName,
            type: "success",
            timer: 1500,
          }).then((r) => {
            this.dialog = false;
          });
        },
        (error) => {
          console.log(error);
        }
      );
    },

    // fetch_data //pagecount

    //        PeriodDT: sessionStorage.getItem("period"),

    // SetReBtHostID(arr_Paremeter) {
    //   console.log(arr_Paremeter);
    //   this.ReBtHostID = arr_Paremeter.ReBtHostID;
    //   this.ReBtHostName = arr_Paremeter.ReBtHostName;
    //   this.$refs.cAddPercent.OnShow(arr_Paremeter.ReBtHostID);
    // },
    // UpdateSuccess() {
    //   this.$refs.cAddDetail.OnUpdateSuccess(this.ReBtHostID, this.ReBtHostName);
    //   this.e1 = 1;
    // },
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    //stepper
    e1: 1,
    // form
    valid: true,

    ReBtHostID: "",
    ReBtHostName: "",
    UpdateCodeTable: "",
  }),
};
</script>

<style scoped></style>
