<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <v-list dense>
        <v-list-item link @click="menuActionClick('detail')">
          <v-list-item-icon>
            <v-icon color="warning">mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>รายละเอียด</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('edit')">
          <v-list-item-icon>
            <v-icon color="blue">mdi-lead-pencil</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>แก้ไขข้อมูล</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          v-if="Status === '0'"
          link
          @click="menuActionClick('block')"
        >
          <v-list-item-icon>
            <v-icon color="red">mdi-cancel</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ระงับ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="Status === '0'"></v-divider>

        <v-list-item
          v-if="Status === '1'"
          link
          @click="menuActionClick('unblock')"
        >
          <v-list-item-icon>
            <v-icon color="green">mdi-account-check</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>เปิดใช้งาน</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="Status === '1'"></v-divider>

        <v-list-item link @click="menuActionClick('del')">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ลบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PopupMenu",

  //   props: [
  //     "Status", //เปิดใช้งาน ระงับ
  //   ],

  methods: {
    Show(id, Status) {
      this.showMenu = true;
      this.id = id;
      this.Status = Status;
    },
    Hide(x, y) {
      this.x = x;
      this.y = y;
    },
    menuActionClick(menu) {
      switch (menu) {
        // case "cus":
        //   this.$router.push({
        //     path: "agent_data",
        //     query: { line_id: this.id },
        //   });
        //   //this.$router.push("/Agent_Data");
        //   break;
        case "del":
          //   alert("del");
          this.$emit("del", this.id);
          break;
        case "detail":
          alert("detail");
          break;
        case "edit":
          alert("edit");
          break;
        case "block":
          alert("block");
          break;
        case "unblock":
          alert("unblock");
          break;
      }
    },
  },
  data: () => ({
    id: null,
    showMenu: false,
    x: 0,
    y: 0,
    Status: 0, //0=เปิด 1=ปิด
  }),
};
</script>

<style></style>
