<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :hide-overlay="$vuetify.breakpoint.mdAndDown"
    persistent
    max-width="600px"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        v-on="on"
        style="background-color: lightpink;"
      >
        <v-list-item-title style="color: black;">
          <v-icon left> mdi-plus-outline </v-icon>เพิ่ม</v-list-item-title
        >
      </v-list-item>
    </template>
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>เพิ่มเจ้ามือ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            ข้อมูลทั่วไป
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            อัตราจ่ายและเปอร์เซ็นต์
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="ma-0 pa-2">
            <AddDetail
              ref="cAddDetail"
              @SetReBtHostID="SetReBtHostID"
              @cancel="OnCancel()"
              @Next="e1 = 2"
            />
          </v-stepper-content>

          <v-stepper-content step="2" class="ma-0 pa-2">
            <AddPercent
              ref="cAddPercent"
              :ReBtHostID="ReBtHostID"
              @Success="UpdateSuccess()"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import AddDetail from "@/components/Data/Export/Bookie/Genaral/Add_Detail";
import AddPercent from "@/components/Data/Export/Bookie/Genaral/Add_Percent";

export default {
  name: "Add_Data",

  components: {
    AddDetail,
    AddPercent,
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.e1 = 1;
          this.$refs.cAddDetail.OnShow();
        });
      } else {
        this.$emit("Close");
      }
    },
  },
  methods: {
    OnCancel() {
      this.dialog = false;
    },
    SetReBtHostID(arr_Paremeter) {
        console.log(arr_Paremeter);
      this.ReBtHostID = arr_Paremeter.ReBtHostID;
      this.ReBtHostName = arr_Paremeter.ReBtHostName;
      this.$refs.cAddPercent.OnShow(arr_Paremeter.ReBtHostID);
    },
    UpdateSuccess() {
      this.$refs.cAddDetail.OnUpdateSuccess(this.ReBtHostID, this.ReBtHostName);
      this.e1 = 1;
    },
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    //stepper
    e1: 1,
    // form
    valid: true,

    ReBtHostID: "",
    ReBtHostName: "",
  }),
};
</script>

<style scoped></style>
