<template>
  <!-- start form -->
  <v-card class="ma-0 pa-0">
    <v-card-text class="ma-0 pa-0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title class="pl-0 pt-0 pb-0 info--text"
          >เปอร์เซนต์ อัตราจ่าย</v-card-title
        >
        <!-- <v-card-subtitle class="pl-0 info--text"
              >ใช้สำหรับตั้งค่าเริ่มต้นเวลาเพิ่มคนขายใหม่</v-card-subtitle
            > -->

        <v-row class="pt-0 pb-0">
          <v-col cols="3" class="text-xs-center"> </v-col>
          <v-col cols="3" class="text-center">
            เปอร์เซ็นต์
          </v-col>
          <v-col cols="3" class="text-center">
            อัตราจ่าย
          </v-col>
          <v-col cols="3" class="text-center">
            โควต้า
          </v-col>
        </v-row>

        <v-row v-for="n in 10" :key="n" class="ma-0 pa-0">
          <v-col cols="3" class="pa-1 pl-1 ma-0">
            <v-text-field
              v-model="Header[n - 1]"
              color="pink"
              value="3 ตัวตรง"
              readonly
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3 pa-1 ma-0">
            <v-text-field
              v-model="Percent[n - 1]"
              color="pink"
              background-color="purple lighten-5"
              type="number"
              class="centered-input"
              :ref="'txtPercent' + n"
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3 pa-1 ma-0">
            <v-text-field
              v-model="Rate[n - 1]"
              color="pink"
              background-color="green lighten-5"
              type="number"
              class="centered-input"
              :ref="'txtPrize' + n"
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3 pa-1 ma-0">
            <v-text-field
              v-model="PriceMax[n - 1]"
              color="pink"
              background-color="orange lighten-5"
              type="number"
              class="centered-input"
              :ref="'txtPriceMax' + n"
              outlined
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="blue darken-1" text @click="submitData">
        บันทึก
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Add_Percent",

  //   props: ["ReBtHostID"],

  methods: {
    //load
    OnShow(p_ReBtHostID) {
      this.ReBtHostID = p_ReBtHostID;
      this.fetch_table();
      //   console.log(this.$refs);
      //   this.$refs.txtPercent[0].focus();
    },

    submitData() {
      //   alert(this.ReBtHostID);
      var ckPercent = true;
      this.Percent.forEach((element, index) => {
        if (parseInt(element) > 100 || parseInt(element) < 0) {
          ckPercent = false;
        }
      });
      if (ckPercent == false) {
        alert("เปอร์เซ็นต์มากกว่า 100 ไม่ได้");
      } else {
        if (this.submitted == false)
          this.OnEdit(this.Percent, this.Rate, this.PriceMax);
      }
    },

    async OnEdit(p_Percent, p_Rate, p_PriceMax) {
      this.submitted = true;

      let credentials = {
        BookieID: this.ReBtHostID,
        Method: "PUT_PRIZE_PERCENT",
        Percent: p_Percent.join(),
        Rate: p_Rate.join(),
        PriceMax: p_PriceMax.join(),
      };

    //   console.log(credentials);
      this.$store.dispatch("bookie/SendBookieData", credentials).then(
        (response) => {
          this.submitted = false;

          this.$emit("Success",this.ReBtHostName);
          //   console.log(response);

          //   this.$alert(
          //     "สำเร็จ.",
          //     "ลงทะเบียนลูกค้ารหัส " + this.form.Name + "เสร็จสิ้น",
          //     "success"
          //   ).then(
          //     () => this.$refs.form.reset(),
          //     this.$refs.form.resetValidation(),
          //   this.$emit("Next");
          //     //emit
          //     // this.$emit("cus_pick", response)
          //   );
        },
        (error) => {
          //   this.login_message(error);
          //   console.log(error);
          switch (error) {
            case "ID_DUPLICATE":
              break;
            // case "Error01":
            //   this.$router.push("/Login");
            //   break;
            // case "Error02":
            //   this.$router.push("/Login");
            //   break;
          }
          this.submitted = false;
        }
      );
    },

    async fetch_table() {
      let credentials = {
        BookieID: this.ReBtHostID,
      };
      await this.$store.dispatch("bookie/get_prize_percent", credentials).then(
        (response) => {
          console.log(response);
          response.prize_percent.forEach((element) => {
            // console.log(element);
            // this.Percent[parseInt(element.BetTypeShowID) - 1] = element.Percent;
            this.Percent.push(element.Percent);
            this.Rate.push(element.Rate);
            this.PriceMax.push(element.PriceMax);

            // console.log(this.Percent);
          });
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  data: () => ({
    // form
    valid: true,
    ReBtHostID: "",
    Header: [
      "3 ตรง",
      "3 โต๊ด",
      "2 บน",
      "2 ล่าง",
      "คู่โต๊ดเล็ก",
      "พวง",
      "หมุน 4 ครั้ง",
      "จม",
      "ลอยบน",
      "ลอยล่าง",
    ],
    Percent: [],
    Rate: [],
    PriceMax: [],
    submitted: false,
  }),
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
